// colors
$base-color: #000;
$base-bg: #fff;

// link color
$color-link: red;

// fonts
$font-default: 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-primary: Verdana, Geneva, sans-serif;

// custom grid
$table-screen: 1023px;
$mobile-screen: 767px;
