.layout-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10rem;
    @include gradient-y(transparent, rgba(0, 0, 0, 0.3));
  }
}

.module-rotate__control {
  cursor: pointer;
}

.layout-footer__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 2;

  .icon-basket {
    width: 2.7rem;
    height: 3.4rem;
    fill: #fff;
  }
}

.layout-photo__inner {
  padding: 2rem 0;
  display: flex;
  justify-content: flex-end;
}

.module-price__icon {
  margin-left: 1rem;
}

.icon-camera {
  width: 3.4rem;
  height: 2.9rem;
  fill: #fff;
  display: block;
}

.module-price {
  display: flex;
  align-items: center;
  font-size: 2rem;
  color: #fff;
  text-shadow: 0 0.2rem 0.2rem rgba(0, 0, 0, 0.25);
}

.module-price__currency {
  margin-right: 0.5rem;
}
