.layout-basket {
  // display: block;
  // overflow: hidden;

  .layout-body {
    // height: 100vh;
    // border: 2px solid blue;
  }
}

.section-basket {
  position: relative;
  z-index: 2;
  padding: 15rem 0 5rem;

  // height: 100%;
  // overflow-y: scroll;
  // -webkit-overflow-scrolling: touch;

  animation-duration: 0.4s;
  animation-name: slideUp;
  border: 2px solid red;
}

@keyframes slideUp {
  from {
    transform: translateY(5rem);
    opacity: 0;
  }

  to {
    transform: translateY(0rem);
    opacity: 1;
  }
}

.scene {
  &:before {
    content: '';
    position: absolute;
    top: 1.5rem;
    left: 50%;
    transform: translateX(-50%);
    width: 1.5rem;
    height: 1.5rem;
    animation-duration: 2s;
    animation-name: rotate;
    animation-iteration-count: infinite;
    border: 0.1rem solid #fff;
    border-bottom: none;
    border-left: none;
    border-radius: 50%;
  }
}

@keyframes rotate {
  from {
    transform: translate(-50%, -50%) rotate(360deg);
  }

  to {
    transform: translate(-50%, -50%) rotate(0deg);
  }
}

// #wrapper {
//   z-index: 1;
// }

// [a-scene] {
//   z-index: 2;
// }

.section-basket__title {
  text-transform: uppercase;
  color: #272a2d;
  font-size: 2.1rem;
  font-weight: 500;
}

.section-basket__inner {
  background-color: #fff;
  padding: 2rem;
}

.section-basket__product {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
}

.section-basket__preview {
  padding-right: 2rem;
  img {
    width: 100%;
    max-width: 7rem;
    height: auto;
    vertical-align: top;
  }
}
