*,
*::before,
*::after {
  box-sizing: inherit;
}

:active,
:focus,
:hover {
  outline: 0;
}

html,
body {
  height: 100%;
  padding: 0;
}

html {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  font-size: 10px;
  @include fluid-type(320px, 1200px, 10px, 16px);
}

body {
  font-size: 1.4rem;
  line-height: 1.28;
  min-width: 320px;
  font-family: $font-default;
  font-weight: 400;
  color: $base-color;
  // overflow: hidden;
  -webkit-font-smoothing: antialiased;
  background-color: $base-bg;
}

a {
  text-decoration: none;
  @include transition(color 0.3s ease);
  color: $color-link;

  .no-touchevents &:hover {
    text-decoration: none;
  }
}

.link-underline {
  text-decoration-skip-ink: auto;
}

p {
  margin: 1.5rem 0;
}

p:empty {
  display: none;
}

input {
  -webkit-appearance: none;
  border-radius: 0;
}

.svg-store {
  display: none;
  height: 0;
  overflow: hidden;
}

.layout {
  position: relative;
  display: flex;
  flex-direction: column;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 8rem;
    @include gradient-y(rgba(0, 0, 0, 0.3), transparent);
    z-index: 10;
  }
}

.layout-body {
  flex: 1 0 auto;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2rem;
  padding-right: 2rem;
}

article {
  hyphens: auto;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media (max-width: $table-screen) {
}

@media (max-width: $mobile-screen) {
}
