.module-gallery {
  overflow: hidden;
  height: 10rem;
  position: relative;
  z-index: 2;

  @extend .noselect;

  .slide-current {
  }

  .tns-outer {
    //margin: 0 -2rem;
  }

  .tns-inner {
    //border: 1px solid red;
    // display: flex;
    // justify-content: center;
  }

  .slider {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);
  }

  .slider-control-centerleft,
  .slider-control-centerright,
  .slider-control-bottomcenter {
    display: none;
  }

  .slide-current {
    .module-gallery__preview {
      img {
        width: 6rem;
        height: 6rem;
      }
    }

    .module-gallery__item {
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
    }
  }
}

.module-gallery__circle {
  border: 0.3rem solid #fff;
  width: 7.2rem;
  height: 7.2rem;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0rem 0 0 0;
  cursor: pointer;
}

.module-gallery__item[data-center='yes'] {
  img {
    width: 6rem;
    height: 6rem;
  }

  .module-gallery__item {
    padding-top: 0;
    padding-bottom: 0;
  }

  .module-gallery__preview {
    &:before {
      opacity: 1;
    }
  }
}

.module-gallery__item {
  text-align: center;
  position: relative;
  width: 6.5rem;
  height: 8rem;
  margin-left: auto;
  margin-right: auto;
}

.module-gallery__preview {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.4);

  img {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    @include transition(width 0.3s, height 0.3s);
    display: block;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 50%;
    border: 0.3rem solid #fff;
    margin: -0.6rem;
    opacity: 0;
    @include transition(opacity 0.3s);
    z-index: 2;
  }
}

#arjs-video {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   border: 2px solid blue;
  //   width: 100% !important;
  //   height: 100% !important;
  //   margin: 0 !important;
}

#wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  &.load {
    &:before {
      display: none;
    }
  }
}

.layout-photo__footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.layout-photo__image {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

#screen-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
}

.layout-photo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}
