.form-group {
  position: relative;
}

.form-label {
  position: absolute;
  left: 0;
  top: 1rem;
  font-size: 1.4rem;
  color: #6a6a6a;
  background-color: #fff;
  z-index: 10;
  padding: 0 2rem;
  font-weight: 300;
  transition: transform 0.2s ease-out, font-size 0.2s ease-out, color 0.2s ease-out;
}

.form-control {
  position: relative;
  width: 100%;
  outline: 0;
  border: 0;
  font-size: 1.4rem;
  padding: 1rem 2rem;
  border-bottom: 0.1rem solid #e6e6e6;
  font-weight: 300;
  color: #757575;

  &::-webkit-input-placeholder {
    color: transparent;
  }
  &:-moz-placeholder {
    color: transparent;
  }
  &::-moz-placeholder {
    color: transparent;
  }
  &:-ms-input-placeholder {
    color: transparent;
  }

  &:focus {
    + .form-label {
      transform: translate(-2rem, -125%);
      font-size: 1.2rem;
      color: #9b9b9b;
    }
  }

  &:not(:placeholder-shown) {
    + .form-label {
      transform: translate(-2rem, -125%);
      font-size: 1.2rem;
      color: #9b9b9b;
    }
  }
}

textarea {
  resize: none;
  &.form-control {
    min-height: 7rem;
  }
}

.form-default__line {
  margin-bottom: 2rem;
}

.form-default__footer {
  display: flex;
  justify-content: flex-end;
}
