.section-title {
  color: #9b9b9b;
  font-size: 1.6rem;
  display: block;
  text-align: center;
  text-transform: uppercase;
  font-weight: 400;
}

.section-head {
  margin-bottom: 2rem;
}
