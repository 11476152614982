.layout {
  // position: relative;
  // background: url(/assets/images/backgrounds/default.jpg) 50% 50% no-repeat;
  // background-size: cover;
  // position: fixed;
  // top: 0;
  // left: 0;
  // width: 100%;
  // height: 100%;
  // overflow-y: auto;
  // -webkit-overflow-scrolling: touch;
}

#root {
  &:before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(/assets/images/backgrounds/default.jpg) 50% 50% no-repeat;
    background-size: cover;
    z-index: -1;
  }
}

.a-canvas {
  z-index: 10;
}

.scene {
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  // width: 100%;
  // height: 100%;

  opacity: 0;

  animation-duration: 1s;
  animation-name: sceneAnimate;
  animation-fill-mode: both;
}

@keyframes sceneAnimate {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

// body {
//   width: 100% !important;
//   height: 100% !important;
//   margin-top: 0px !important;
//   margin-left: 0px !important;
// }

#root {
  height: 100%;
  position: relative;
}

.layout-home {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
}

.scene {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
