.layout-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  z-index: 13;
}

.layout-header__inner {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
}

.logo-fancyclover {
  width: 13.3rem;
  height: 2.5rem;
  display: block;
}

.layout-header__back {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);

  .icon-back {
    fill: #fff;
    width: 2.7rem;
    height: 2.1rem;
  }
}
