.btn {
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.btn-default {
  padding: 0;
  margin: 0;
  border: 0.1rem solid #40dedf;
  background-color: #fff;
  color: #40dedf;
  font-size: 1.6rem;
  font-weight: 300;
  text-transform: uppercase;
  padding: 1.5rem 3rem;

  .no-touchevents &:hover {
  }
}

.btn-primary {
  .no-touchevents &:hover {
  }
}

.btn-back {
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  display: block;
}

.btn-download {
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  display: block;

  .icon-download {
    width: 3.4rem;
    fill: #fff;
    height: 3.4rem;
  }
}
